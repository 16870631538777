import React from 'react';
import { Link } from "gatsby";
import Layout from '../components/layout';
import Card, { CardContent, CardImage, CardTitle } from '../elements/card';

const Quatre_therapies = () => (
  <Layout>
    <div className="columns">
        <div className="column">
            <Link to='/therapie_individuelle'>
                <Card>
                    <CardContent>
                        <CardTitle title="La Thérapie Individuelle"/>
                    </CardContent>
                    <CardImage src="therapie1" alt="thérapie individuelle" hoverable="true" size="is-4by3" />      
                </Card>
            </Link>
        </div>
        <div className="column">
            <Link to='/therapie_de_couple'>
                <Card>
                    <CardContent>
                        <CardTitle title="La Thérapie de Couple"/>
                    </CardContent>
                    <CardImage src="ncouple1" alt="thérapie de couple" hoverable="true" size="is-4by3" />
                </Card>
            </Link>
        </div>
    </div>
    <div className="columns">
        <div className="column">
            <Link to='/therapie_familiale'>
                <Card>
                    <CardContent>
                        <CardTitle title="La Thérapie Familiale"/>
                    </CardContent>
                    <CardImage src="therapie3" alt="thérapie familiale" hoverable="true" size="is-4by3" />
                </Card>
            </Link>
        </div>
        <div className="column">
            <Link to='/therapie_en_groupe'>
                <Card>
                    <CardContent>
                        <CardTitle title="La Thérapie en Groupe"/>
                    </CardContent>
                    <CardImage src="therapie4" alt="thérapie de groupe" hoverable="true" size="is-4by3" />
                </Card>
            </Link>
        </div>
    </div>
  </Layout>
)

export default Quatre_therapies;